@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/brands.min.css');

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1; }

.fa-facebook-square:before {
    content: "\f082"; }
.fa-twitter-square:before {
    content: "\f081"; }
.fa-linkedin:before {
    content: "\f08c"; }

.copyrights{
    font-feature-settings: "case" 1, "tnum" 1;
}