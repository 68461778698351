@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/line-awesome/css/line-awesome.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --archives-primary:#A81E65
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: "Inter", sans-serif;
  background: #f3f4f6;
}

header {
  h1 {
    font-feature-settings: 'case';
    font-weight: 900;
  }
}

.form{
  label{
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.3rem;
  }
  input[type='text'], input[type='date'], select{
    border: 2px solid #dfdfdf;
    border-radius: 0.5rem;
    padding: 0.5rem;
    outline: none;
    width: 100%;
    &:hover{
      border-color: #999999;
    }
    &:focus{
      border-color: var(--archives-primary);
    }
    &.w-auto{
      width: auto !important;
    }
    &:focus+span.helper-text, &:focus ~ label{
      color: var(--archives-primary);
    }
  }

  select{
    background-color: #ffffff;
  }
}

.rdw-editor-main{
  border: 2px solid #dfdfdf;
  border-radius: 0.5rem;
}
